table.TableGrid .MuiTableCell-head {
  background-color: #F8F8F8;
  vertical-align: middle;
}
table.TableGrid .MuiTableCell-root {
  min-width: 42px;
  max-width: 350px;
  width: auto;
}

table.TableGrid .MuiTableRow-root .MuiTableCell-root:first-child {
  padding-right: 0;
  padding-left: 16px;
}



table.TableGrid .MuiTableRow-head .MuiTableCell-root:last-child {
  z-index: 3 !important;
  box-shadow: none !important;
}

table.TableGrid .MuiTableRow-root .MuiTableCell-root:last-child {
  position:sticky;
  right: 0;
  z-index: 1;
  box-shadow: -4px 0 4px -2px rgba(0,0,0,0.2);
}