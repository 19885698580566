.MuiDataGrid-columnHeaders {
	position: sticky !important;
	top: 0;
	z-index: 3;
	background: white;
  }
  
  .sticky-name {
	position: sticky !important;
	left: 0;
	z-index: 2;
	background: white;
  }

  .sticky-reference {
	position: sticky !important;
	left: 200px;
	z-index: 2;
	background: white;
  }

  .MuiDataGrid-cell {
	background: white;
  }